.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: url(bgmp-min.png) no-repeat;
  background-size: cover;
  max-height: 100vh;
}
header {
  margin-top: 1vh;
}
h1 {
font-size: 7em;
font-style: italic;
text-shadow: #6bc1c1 7px -1px;
}
.navbar {
  display: flex;
  justify-content: space-around;
  font-family: 'Teko', sans-serif;
  font-size: 3em;
}
.logo {
display: flex;
justify-content: center;
line-height: 3em;
  padding-top: 10px;
  text-shadow: whitesmoke 3px -1px;
}
.menu {
  display: flex;

}
.comso {
  text-align: center;
}
@media (max-width: 780px) {
  h1 {
      font-size: 5em;
      font-style: italic;
      text-shadow: #6bc1c1 5px -1px;
  } 
  .navbar {
      font-size: 1.5em;}
}

@media (max-width: 550px) {
  h1 {
      font-size: 3em;
      text-shadow: #6bc1c1 3px -1px;
  } 
  .navbar {
      font-size: 1em;}
}